/** @jsx jsx */
import { jsx } from "theme-ui";
import { Layout, Content, Section } from "maker-ui";
import PropTypes from "prop-types";
import HeaderComp from "../components/Header";
import FooterComp from "../components/Footer";

import options from "../mark-ui/options";
import theme from "../mark-ui/theme";
import Seo from "../components/seo";
import SiteAccessRestriction from "../components/SiteAccessRestriction";

const LayoutHome = ({
  children,
  seoTitle = " ",
  contentHeaderStyle = " ",
  contentHeader = " ",
  isIframe,
  headerCustomLogo,
}) => {
  return (
    <Layout theme={theme} options={options}>
      <Seo title={seoTitle} />
      <HeaderComp headerCustomLogo={headerCustomLogo} />
      <Content>
        <Section sx={options[contentHeaderStyle]}>{contentHeader}</Section>
        <Section>
          <div
            className="container"
            sx={{
              mb: 5,
              ...(isIframe === true && {
                padding: "0",
                margin: "0",
                width: "100%",
                minWidth: "100%",
                height: "85vh",
                overflow: "hidden",
              }),
            }}
          >
            <SiteAccessRestriction />
            {children}
          </div>
        </Section>
      </Content>
      {isIframe !== true && <FooterComp />}
    </Layout>
  );
};

LayoutHome.defaultProps = {
  contentHeaderStyle: "",
  contentHeader: "",
  isIframe: false,
  headerCustomLogo: "",
};

LayoutHome.propTypes = {
  children: PropTypes.element.isRequired,
  seoTitle: PropTypes.string.isRequired,
  contentHeaderStyle: PropTypes.string,
  contentHeader: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  isIframe: PropTypes.bool,
  headerCustomLogo: PropTypes.string,
};

export default LayoutHome;
