/** @jsx jsx */
import { Box, jsx } from "theme-ui";
import { Link } from "gatsby";
import LayoutHome from "../layout/LayoutHome";

const AtlantaCorpDonation = () => {
  return (
    <LayoutHome
      seoTitle="Corporate Donations - Donations"
      contentHeaderStyle="contentHeaderText"
      contentHeader={<h3> Corporate Donations</h3>}
    >
      <Box>
        <p sx={{ pb: 0, mb: 1, mt: 3 }}>
          Corporate Donations – How Your Corporate Gift Helps
        </p>
        <p>
          The Heartfulness Institute is a nonprofit global organization with
          more than 50,000 volunteers and 8,000 trainers in relaxation and
          meditation techniques in more than 130 countries in the world. Our
          mission is to help develop peaceful, healthy and holistically educated
          individuals and children, families and communities, organizations and
          businesses, and government organizations with a spirit of human
          well-being and oneness.
        </p>
        <p>
          Confucius once said, “If you feed a man fish, you feed him for one
          day. If you teach him how to fish, he can feed himself for the rest of
          his life.” The Heartfulness Institute offers the following programs
          and services with the goal of teaching humanity fundamental skills
          based on personal well-being, mental health and conscious living:
        </p>
        <ul sx={{ fontSize: "18px", li: { py: 1 } }}>
          <li>
            Programs to children to improve their creativity, interest in
            learning and observational abilities.
          </li>
          <li>
            Teaching training on Heartfulness methods of values education.
          </li>
          <li>
            Free community relaxation, meditation and rejuvenation practices
            based on a century-old system of meditation suitably, modified as
            Heartfulness for the modern day.
          </li>
          <li>
            Self-development programs in colleges and universities, to create
            well-balanced students for a better future.
          </li>
          <li>
            Wellness programs for corporate employees to learn to relax and
            rejuvenate, in order to lower stress in the workplace and improve
            productivity and teamwork.
          </li>
        </ul>
        <p>
          <strong>
            The Heartfulness Institute is 100% volunteer managed and operated.
            We are seeking philanthropic corporate donations to support this
            effort for human well-being
          </strong>
          . Your contributions in this very important endeavor will go along way
          in sustaining and growing these programs. We are grateful for your
          donations and support.
        </p>
        <p>
          For more information on how you can directly support The Heartfulness
          Institute, contact{" "}
          <a
            sx={{ color: "#1da1f2" }}
            href="mailto:us.donations@heartfulness.org"
          >
            us.donations@heartfulness.org
          </a>{" "}
          or directly <Link to="/usabhyasis"> make an online donation.</Link>
        </p>
      </Box>
    </LayoutHome>
  );
};

export default AtlantaCorpDonation;
